import { createRouter, createWebHistory } from "vue-router";
import MainLayout from "@/layouts/MainLayout.vue";
import { getFromLocal } from "@/helper/local.helper";

const routes = [
  {
    path: "/",
    name: "login",
    component: () => import("@/views/auth/Login.vue"),
  },
  {
    path: "/",
    component: MainLayout,
    children: [
      {
        path: "/home",
        name: "dashboard",
        component: () => import("@/views/dashboard/Dashboard.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/rawat-jalan",
        name: "rawat-jalan",
        component: () => import("@/views/rawat-jalan/RawatJalan.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/rawat-inap",
        name: "rawat-inap",
        component: () => import("@/views/rawat-inap/RawatInap.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/keuangan",
        name: "keuangan",
        component: () => import("@/views/keuangan/Keuangan.vue"),
        meta: { requiresAuth: true },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = getFromLocal('token');
  if (to.meta.requiresAuth && !isAuthenticated) {
    next("/");
  } else if (to.path === "/" && isAuthenticated) {
    next("/home");
  } else {
    next();
  }
});

export default router;
