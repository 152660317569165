import AES from 'crypto-js/aes';
import encUtf8 from 'crypto-js/enc-utf8';
const secretKey = 'statistikrsuaminahblitar';

const encrypt = (rawData) => {
  const cipherText = AES.encrypt(JSON.stringify(rawData), secretKey).toString();
  return cipherText;
};

const decrypt = (ciphertext) => {
  const bytes = AES.decrypt(ciphertext, secretKey);
  return JSON.parse(bytes.toString(encUtf8));
};

export { encrypt, decrypt };
