import moment from "moment";

const getCurrentTime = () => {
  return moment().locale('id').format("HH:mm:ss");
};

const getCurrentYear = () => {
  return moment().locale('id').year();
};

const getYearsSince = (startYear) => {
  const years = [];
  for (let year = getCurrentYear(); year >= startYear; year--) {
    years.push(year);
  }
  return years;
};

const getMonthsId = () => {
  moment.locale("id");
  return moment.months();
};

const getShortMonths = () => {
  moment.locale("en");
  return moment.monthsShort();
};

const getMonthsObject = () => {
  const shortMonths = getShortMonths();
  const monthsId = getMonthsId();
  const monthsArray = shortMonths.map((shortMonth, index) => ({[ `${shortMonth}`]: monthsId[index]}));
  return monthsArray.reduce((acc, curr) => ({...acc, ...curr}), {});
};

const getFullMonthNameId = (shortNameEn) => {
  const monthsObject = getMonthsObject();
  return monthsObject[shortNameEn];
};

const getDaysId = () => {
  moment.locale("id");
  return moment.weekdays();
};

const getCurrentDate = (format) => {
  return moment().locale('id').format(format);
}

export { getCurrentYear, getYearsSince, getMonthsId, getFullMonthNameId, getDaysId, getCurrentTime, getCurrentDate };
