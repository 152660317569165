<template>
    <div class="d-flex align-items-center" :class="classList"
        style="gap: .75rem;">
        <div class="spinner-border spinner-border-sm" role="status" aria-hidden="true">
        </div>
        <strong style="font-size: x-large;">
            <slot></slot>
        </strong>
    </div>
</template>

<script>
export default {
    name: 'Loader',
    props: {
        classname: {
            type: String,
            default: ''
        }
    },
    computed: {
        classList() {
            return this.classname
        }
    }
}
</script>

<style></style>