<template>
  <div class="wrapper">
    <!-- Navbar -->
    <l-navbar/>
    <!-- /.navbar -->

    <!-- Main Sidebar Container -->
    <l-sidebar/>

    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      

      <!-- Main content -->
      <router-view></router-view>
      <!-- /.content -->
    </div>
    <!-- /.content-wrapper -->

    <!-- Control Sidebar -->
    <aside class="control-sidebar control-sidebar-dark">
      <!-- Control sidebar content goes here -->
    </aside>
    <!-- /.control-sidebar -->
  </div>
</template>

<script>
  import LNavbar from "@/layouts/include/Navbar.vue";
  import LSidebar from "@/layouts/include/Sidebar.vue";
  export default ({
    name: 'MainLayout',
    components: {
      LSidebar, LNavbar
    }
  })
</script>

<style>

</style>