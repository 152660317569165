<template>
  <section class="content-header">
        <div class="container-fluid">
          <div class="row row-cols-1 row-cols-md-2 mb-2">
            <div class="col-sm-6">
              <h1 v-if="title" class="font-weight-bold text-lg">
                <i :class="icon" v-if="icon" class="mr-1"></i>
                <span>{{ title }}</span>
              </h1>
            </div>
            
            <div class="col-sm-6">
              <div class="d-flex justify-content-start justify-content-md-end align-items-center font-weight-bold" style="gap: 2rem;">
                <p><i class="far fa-clock mr-1"></i> <span>{{ currentTime }} WIB</span></p>
                <p><i class="far fa-calendar-alt mr-1"></i> <span>{{ current.date }}</span></p>
              </div>
            </div>
            
          </div>
        </div>
      </section>
</template>

<script>
import { getCurrentTime, getCurrentDate } from '@/helper/moment.helper';

export default{
  props:{
    title:{
      type: String,
      default: null
    },
    icon:{
      type: String,
      default: null
    }
  },
  data(){
    return {
      current: {
        date: '',
        time: ''
      }
    }
  },
  methods: {
    init(){
      this.current.date = getCurrentDate('dddd, DD MMMM YYYY');
      this.current.time = getCurrentTime();
    },
    runLiveClock() {
      setInterval(() => {
        this.current.time = getCurrentTime();
      }, 1000);
    }
  },
  beforeMount(){
    // let title = this.title
    // $('title').html(title)
  },
  created() {
    this.init();
    this.runLiveClock();
  },
  computed: {
    currentTime() {
      return this.current.time;
    },
  }
}
</script>