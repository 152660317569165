import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ContentHeader from "@/components/ContentHeader.vue";
import Content from "@/components/Content.vue";
import Loader from "@/components/Loader.vue";
import Select2 from '@/components/Select2';

import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import './assets/css/style.css';


const app = createApp(App)
app.use(store)
app.use(router)
app.component('content-header', ContentHeader)
app.component('content', Content)
app.component('Loader', Loader);
app.component('Select2', Select2);
app.component('EasyDataTable', Vue3EasyDataTable);
app.mount('#app')
