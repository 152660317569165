<template>
  <aside class="main-sidebar sidebar-dark-primary elevation-4">
    <!-- Brand Logo -->
    <router-link to="/home" class="brand-link">
      <img src="/dist/img/logo.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-3"
        style="opacity: .8">
      <span class="brand-text font-weight-light">RSU Aminah Blitar</span>
    </router-link>

    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar user (optional) -->
      <!-- <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <div class="image">
          <img src="/dist/img/user.jpg" class="img-circle elevation-2" alt="User Image">
        </div>
        <div class="info">
          <a href="#" class="d-block">
            {{ user.fullname }}
          </a>
        </div>
      </div> -->

      <!-- Sidebar Menu -->
      <nav class="mt-2">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          <nav-item icon="fas fa-home" link="/home">
            <template v-slot:parent_item>
              Dashboard
            </template>
          </nav-item>

          <nav-item icon="fas fa-user-injured" link="/rawat-jalan">
            <template v-slot:parent_item>
              Rawat Jalan
            </template>
          </nav-item>

          <nav-item icon="fas fa-procedures" link="/rawat-inap">
            <template v-slot:parent_item>
              Rawat Inap
            </template>
          </nav-item>

          <nav-item icon="fas fa-coins" link="/keuangan">
            <template v-slot:parent_item>
              Keuangan
            </template>
          </nav-item>

          <!-- <nav-item icon="fas fa-times-circle" link="#" @click="logout">
            <template v-slot:parent_item>
              Logout
            </template>
          </nav-item> -->

        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>
</template>

<script>
import NavItem from '@/layouts/include/NavItem.vue';
import { mapGetters, mapActions } from "vuex";
import { removeFromLocal } from '@/helper/local.helper';

export default {
  components: {
    NavItem
  },
  // computed:{
  //   ...mapGetters({
  //     user :'auth/user'
  //   })
  // },
  methods:{
    // ...mapActions({
    //   logout_system: 'auth/logout'
    // }),

    selectMenu(item){
      console.log(item);
    },

    ...mapActions({

    }),
    
    logout(){
      // this.logout_system()
      removeFromLocal();
      this.$router.push('/');
    },
  }
};
</script>

<style scoped>
.nav-link{
  color: rgb(206, 206, 206);
}
</style>